import {DatatableSearchComponent} from './header/DatatableSearchComponent'

const TableHeader = ({initialState}: any) => {
  return (
    <>
      <DatatableSearchComponent />
    </>
  )
}

export {TableHeader}
