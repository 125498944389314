import {useMutation, useQueryClient} from 'react-query'
import swal from 'sweetalert'
import {QUERIES} from '../../../../../_metronic/helpers'
import {Can} from '../../../../../_metronic/redux/ability'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'

const DatableGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation({
    mutationFn: () => deleteSelectedUsers(selected),
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries({queryKey: [`${QUERIES.USERS_LIST}-${query}`]})
      clearSelected()
    },
  })

  const handlerDelete = async () => {
    // async () => await deleteItem.mutateAsync()
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this product!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteSelectedItems.mutateAsync()
        swal('Your product has been deleted!', {
          icon: 'success',
        })
      } else {
        swal('Your product is safe!')
      }
    })
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>
      <Can access='Delete User' group='users'>
        <button type='button' className='btn btn-danger btn-sm' onClick={() => handlerDelete()}>
          Delete
        </button>
      </Can>
    </div>
  )
}

export {DatableGrouping}
