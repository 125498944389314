import {useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import Select from 'react-select'
import {toast} from 'react-toastify'
import {
  useAddSubsectionMutation,
  useGetAPIsQuery,
  useGetSectionsQuery,
} from '../../../../../_metronic/redux/slices/auth'

const CreateSubsection = () => {
  const [section, setSection] = useState(false)
  const {data: APIs, isLoading} = useGetAPIsQuery(undefined)
  const {data: sectionsData, isLoading: sectionLoading} = useGetSectionsQuery(undefined)

  const [addSubsection] = useAddSubsectionMutation()

  const [post, setPost] = useState({
    name: '',
    purpose: '',
    route: '',
    permission_group_id: 0,
    api_uri_ids: [],
  })

  const APIsOptions = () => {
    let lists: any = []
    if (APIs?.data) {
      // eslint-disable-next-line array-callback-return
      APIs?.data.map((item: any) => {
        lists.push({
          ...item,
          label: `${item.http_method}:${item.base_route}${item.api_route}`,
          value: item.id,
        })
      })
    }

    return lists
  }
  const sectionsOptions = () => {
    let lists: any = []
    if (sectionsData.data) {
      // eslint-disable-next-line array-callback-return
      sectionsData?.data.map((item: any) => {
        lists.push({
          ...item,
          label: item.name,
          value: item.id,
        })
      })
    }

    return lists
  }

  const handleSubmit = async () => {
    if (post.name === '' && post.route === '') {
      toast.error('Field required')
    } else {
      try {
        const res = await addSubsection(post).unwrap()

        if (res.success && res.status_code === 200) {
          toast.success(res.message)
          setPost({
            name: '',
            purpose: '',
            route: '',
            permission_group_id: 0,
            api_uri_ids: [],
          })
          setSection(false)
        } else {
          toast.error('Section create failed')
        }
      } catch (error) {
        // console.log(error)
        toast.error('Section create failed')
      }
    }
  }

  if (isLoading || sectionLoading) return <></>

  return (
    <>
      <button className='btn btn-sm btn-light-info' onClick={() => setSection(!section)}>
        <i className='fas fa-plus'></i> Permission
      </button>
      <Modal show={section} onHide={() => setSection(!section)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter name'
              value={post.name}
              onChange={(e) => setPost({...post, name: e.target.value})}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='route'>
            <Form.Label>Route</Form.Label>
            <Form.Control
              type='text'
              placeholder='/dashboard'
              value={post.route}
              onChange={(e) => setPost({...post, route: e.target.value})}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='route'>
            <Form.Label>Choose Group</Form.Label>
            <Select
              defaultValue={[]}
              name='section'
              options={sectionsOptions()}
              onChange={(e: any) => setPost({...post, permission_group_id: e.id})}
              classNamePrefix='select'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='route'>
            <Form.Label>API Route</Form.Label>
            <Select
              defaultValue={[]}
              isMulti
              name='api'
              options={APIsOptions()}
              onChange={(e: any) => setPost({...post, api_uri_ids: e.map((f: any) => f.value)})}
              classNamePrefix='select'
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setSection(!section)}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateSubsection
